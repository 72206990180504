@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Maven+Pro:400,500,700,900), '~bootstrap/scss/bootstrap', '~font-awesome/scss/font-awesome.scss';

body {
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
	color: #363e42;
	font-size: 14px;
}

.form-control {
	font-size: 14px;
}

h1,
.h1 {
	font-size: 36px;
}

h2,
.h2 {
	font-size: 30px;
}

h3,
.h3 {
	font-size: 24px;
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 {
	font-size: 14px;
}

h6,
.h6 {
	font-size: 12px;
}

p,
pre {
	margin: 0 0 10px;
}

.text-link {
	cursor: pointer;
	color: $blue;
	display: inline;
	background-color: transparent;
	border: 0;
	padding: 0;

	&:hover {
		text-decoration: underline;
	}
}

input[type='radio'] {
	height: 13px;
	width: 13px;
}
