.profile-email {
	.radio-box {
		display: flex;
		flex-direction: column;
		input[type='radio'] {
			margin: 5px 10px;
		}
	}
	.button-container {
		.ops-button {
			margin-right: 8px;
			margin-bottom: 8px;
		}
	}
}
