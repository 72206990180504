div.ajax-annotation-window {
	div.overlay.visible {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 2000;
	}

	div.content {
		position: fixed;
		bottom: 0;
		right: 20px;
		z-index: 2100;

		.card {
			margin-bottom: 0;
			border-radius: 0;

			.card-header {
				color: #31708f;
				background-color: #d9edf7;
			}
			.card-header:first-child {
				border-radius: 0;
			}

			.card-body {
				width: 600px;
				padding: 0;

				.query-path {
					background-color: #f0f0f0;
					font-family: 'Courier New', sans-serif;
					font-size: 16px;
					font-weight: bold;
					padding: 15px;
					width: 100%;
					overflow-x: auto;
					white-space: nowrap;
					margin-bottom: 5px;
				}

				.nav > li > a {
					padding: 5px 10px;
				}
				.tab-content {
					padding: 15px;
				}

				.annotated-json pre {
					max-height: 50vh;
					overflow-y: scroll;
				}
			}
			.card-footer {
				text-align: center;
			}
		}
	}
}
