.payment-options {
	.radio-box {
		display: flex;
		flex-direction: row;

		input[type='radio'] {
			margin: 5px 10px;
		}
	}
	.visible-error {
		display: block;
	}
}
