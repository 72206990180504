.login {
	background: #f0f0f0;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;

	h3 {
		margin: 0 0 25px;
	}
	button {
		margin: 0 0 15px;
	}
}
