.purchase {
	.header {
		text-align: center;

		h1,
		h4 {
			font-family: 'Maven Pro', sans-serif;
		}

		h1 {
			margin-top: 20px;
			margin-bottom: 25px;
		}

		h4 {
			margin-bottom: 50px;

			img {
				height: 1.5em;
				margin-top: -5px;
			}
		}
	}

	.purchase-overview {
		text-align: center;
		margin-bottom: 50px;

		img {
			margin: 25px 0 15px;
		}

		h4 {
			margin-top: 0;
		}

		h3 {
			margin-top: 30px;
		}
	}
}
