.p2p {
	p {
		margin: 25px 0;
	}
	.available-balance {
		margin-bottom: 40px;
	}

	.p2p-form {
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 500px;
		margin-bottom: 20px;

		.form-group,
		label {
			margin-bottom: 0;
		}

		> * {
			margin-right: 10px;
		}

		> button {
			width: 110px;
		}

		.amount-input {
			margin-right: 4px;
			flex-grow: 1;
		}
	}

	.payment-options-form {
		margin-top: 50px;
	}
}
