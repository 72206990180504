div.annotated-json {
	span.string {
		color: green;
	}
	span.number {
		color: darkorange;
	}
	span.boolean {
		color: blue;
	}
	span.null {
		color: magenta;
	}
	span.key {
		color: red;
	}

	span.has-details {
		text-decoration: underline;
		cursor: pointer;
	}

	.popover {
		color: #303030;

		h3 {
			font-family: Mulish, sans-serif;
		}
	}

	span.underlined {
		text-decoration: underline;
	}
	p.help {
		min-height: 50px;
		border: 1px solid #ddd;
		border-radius: 4px;
		padding: 5px;
	}
}
