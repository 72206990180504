.profile {
	display: flex;
	flex-direction: row;

	.link-container {
		min-width: 150px;
		display: flex;
		flex-direction: column;

		.nav-link {
			padding: 8px;

			&.active {
				background-color: #047bfe;
				color: white;
				border-radius: 4px;
			}
		}
	}

	.route-container {
		flex-grow: 1;
	}
}
