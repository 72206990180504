.profile-password {
	.button-container {
		display: flex;
		flex-direction: row;
		justify-content: center;

		.button-ops {
			margin: 5px;
		}
	}
}
