.index {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	flex-direction: column;
	text-align: center;

	p {
		max-width: 600px;
		display: inline-block;
	}
	h1 {
		margin: 0 0 25px;
	}
}
