.account-statement {
	margin-top: 50px;

	.progress-spinner {
		margin: 25px 0;
		text-align: center;

		i {
			font-size: 300%;
		}
	}
}
