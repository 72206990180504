.adyen-credit-card-option {
	.logo {
		max-width: 150px;
		max-height: 30px;
	}

	.radio-box {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;

		input[type='radio'] {
			margin: 5px 10px;
		}
	}

	.cvv-single {
		width: 75px;
		margin-top: 10px;
	}

	.credit-card-box {
		padding: 30px;
		margin: 25px 30px;
	}

	.credit-card-box-hidden {
		display: none;
	}

	.credit-card-stored {
		display: inline;
	}

	.credit-card-stored-hidden {
		display: none;
	}
}
