@import '../../index.scss';

.service-area {
	.navbar {
		margin-bottom: 40px;
		padding: 0;
		.container {
			justify-content: flex-start;
			padding-left: 15px;
			padding-right: 15px;
		}
		.main-navigation {
			flex-grow: 1;
		}
		.nav-link {
			padding: 1rem;

			&.active {
				background-color: $gray-200;
			}
		}
	}
}
