.credit-card-option {
	.logo {
		max-width: 150px;
	}
	.radio-box {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;

		input[type='radio'] {
			margin: 5px 10px;
		}
	}
	.cvv-single {
		width: 75px;
		margin-top: 10px;
	}
	.credit-card-box {
		padding: 15px;
		margin: 15px 30px;
	}
}
