.mock-pay-option {
	.logo {
		max-width: 150px;
	}
	.radio-box {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;

		input[type='radio'] {
			margin: 5px 10px;
		}
	}
}
